import {
  ANALYTICS_PAGE_TYPE,
  DEFAULT_LANGUAGE,
  EVENT,
  pushToDataLayer,
} from '@wr/web-shared';
import {
  AppContextProviderShared,
  getCountryCodeFromLocale,
  getSendCountryCodeFromLocale,
} from '@wr/web-ui';
import type { GetServerSideProps } from 'next';
import React, { FC, useEffect, useState } from 'react';

import Layout from '@/components/layout';
import { PageMetadataComponent } from '@/components/page-metadata';
import { DEFAULT_LOCATION } from '@/constants';
import { AppContextProvider } from '@/context';
import { getPage, getPageData } from '@/services';
import { selectOrganizationMarkup } from '@/services/contentful';
import { getContentfulPageQueryVariables } from '@/services/contentful/page/page.utils';
import { getRedirects } from '@/services/contentful/redirects';
import { getLinksWithoutRedirects } from '@/services/contentful/redirects/redirect.utils';
import { getRegionCode } from '@/services/contentful/region-code/region-code.service';
import { Countries, parseCountryDataPartial } from '@/services/rest';
import { useCountriesStore } from '@/state';
import { PageProps } from '@/types';
import { getAllModuleItems, logger } from '@/utils';
import { getBreadcrumbs } from '@/utils/breadcrumbs.utils';
import { resolveComponents } from '@/utils/component-resolver';
import { resolveState } from '@/utils/state-resolver';

import { Theme } from '../components/theme';
import { AppPropsWithLayout } from './_app';

const Page: FC<PageProps & AppPropsWithLayout> = props => {
  const {
    __typename,
    modules,
    name,
    isWebView,
    preview,
    sys,
    title,
    description,
    breadcrumbs,
    noIndex,
    header,
    originUri,
    footer,
    countries,
    regions,
    messages,
    pageLinksWithRegionsAndSlugs,
    analyticsPageType,
    sendCountry,
    receiveCountry,
    locale,
    router,
    regionCode,
    slug,
    organizationSchema,
  } = props;
  const hydrate = useCountriesStore(state => state.hydrate);
  React.useEffect(() => {
    if (countries) hydrate(countries);
  }, [hydrate, countries]);

  const resolvedComponents = resolveComponents(modules || [], {}, header);

  const resolvedState = resolveState(modules || [], {
    FaqSection: { receiveCountry: receiveCountry?.iso2 },
  });

  const [fullUrl, setFullUrl] = useState('');
  useEffect(() => {
    setFullUrl(window.location.href);
  }, []);

  useEffect(() => {
    pushToDataLayer({
      event: EVENT.PAGE_LOADED,
      page_type: analyticsPageType,
      page_name: fullUrl,
      page_language: locale,
      sender_country: sendCountry?.name,
      sender_country_iso: sendCountry?.iso2.toUpperCase(),
    });
  }, [analyticsPageType, fullUrl, locale, sendCountry]);

  return (
    <AppContextProviderShared
      isWebView={isWebView}
      originUri={originUri}
      regions={regions as string[]}
      messages={messages}
      sendCountry={parseCountryDataPartial(
        router.query.from
          ? countries.find(country => country.iso2 === router.query.from)
          : sendCountry,
      )}
      receiveCountry={parseCountryDataPartial(
        router.query.to
          ? countries.find(country => country.iso2 === router.query.to)
          : receiveCountry,
      )}
      locale={locale}
      analyticsPageType={analyticsPageType}
    >
      <AppContextProvider placeholder="TODO: Add SW specific props here">
        <PageMetadataComponent
          title={title}
          description={description}
          originUri={originUri}
          pathname={slug ? `/${slug}` : ''}
          noIndex={noIndex}
          pageLinksWithRegionsAndSlugs={pageLinksWithRegionsAndSlugs}
          regions={regions}
          analyticsPageType={analyticsPageType}
          regionCode={regionCode}
          organizationSchema={organizationSchema}
          modules={modules}
        />
        <Theme>
          {resolvedState}
          <Layout
            __typename={__typename}
            name={name}
            preview={preview}
            sys={sys}
            footer={{ ...footer }}
            header={isWebView ? null : header}
            breadcrumbs={breadcrumbs}
            languageFromCookie={getCountryCodeFromLocale(locale)}
          >
            {resolvedComponents}
          </Layout>
        </Theme>
      </AppContextProvider>
    </AppContextProviderShared>
  );
};

export const getServerSideProps: GetServerSideProps<
  PageProps | Record<string, unknown>,
  { slug?: string[] }
> = async ({ preview = false, locale: nextLocale, defaultLocale, params }) => {
  try {
    let pageType;
    const region = (() => {
      if (nextLocale && nextLocale !== defaultLocale) return nextLocale;
      return DEFAULT_LANGUAGE;
    })();

    const sendCountryCode =
      getSendCountryCodeFromLocale(nextLocale) || DEFAULT_LOCATION;

    const receiveCountryCode = undefined;

    const { slug } = await getContentfulPageQueryVariables({
      params,
    });

    const page = await getPage({
      slug,
      locale: region,
      region,
      preview,
    });

    if (!page) {
      logger.error({ slug }, 'Page was not found in contentful');

      return {
        notFound: true,
      };
    }

    const HP_REDIRECT_PROPS = {
      redirect: {
        destination: `/${region}`,
        permanent: true,
      },
    };

    if (pageType === ANALYTICS_PAGE_TYPE.Country) {
      return HP_REDIRECT_PROPS;
    }

    const regionCode = await getRegionCode();

    const [
      pageData,
      modules,
      { data: countries },
      pageRedirects,
    ] = await Promise.all([
      getPageData({
        pageId: page.sys.id,
        locale: region,
        slug: page?.slug || '',
        analyticsPageType: pageType ?? '',
        preview,
        sendCountryCode,
      }),
      getAllModuleItems({
        locale: region,
        modules: page?.modulesCollection?.items,
        preview,
      }),
      Countries.get(),
      getRedirects(),
    ]);
    const breadcrumbs = getBreadcrumbs(pageData);

    const regionSlug = page.regions?.map(region => ({
      slug: slug ? `${region}/${slug}` : region,
      title: region || '',
    }));

    const pageLinksWithRegionsAndSlugs = getLinksWithoutRedirects(
      regionSlug,
      pageRedirects,
    );

    const regions =
      page.regions?.filter((region): region is string => Boolean(region)) || [];

    const sendCountry = sendCountryCode
      ? countries?.find(country => country.iso2 === sendCountryCode) ?? null
      : null;

    const receiveCountry = receiveCountryCode
      ? countries?.find(country => country.iso2 === receiveCountryCode) ?? null
      : null;

    const organizationSchema = selectOrganizationMarkup(pageData, page);

    return {
      props: {
        sys: page.sys,
        __typename: page.__typename,
        name: page.name,
        title: page.title,
        description: page.description,
        noIndex: page.noIndex,
        footer: {
          ...page.footer,
          legalMessage: pageData?.legalMessagingCollection?.items[0],
        },
        header: {
          ...pageData?.layoutHeaderCollection?.items[0],
          headerPosition: page.headerPosition,
          headerTheme: page.headerTheme,
        },
        breadcrumbs,
        modules,
        originUri: process.env.WEB_CMS_SW_URI,
        countries: countries || [],
        regions,
        messages: pageData?.messagesCollection?.items[0] || {},
        pageLinksWithRegionsAndSlugs,
        analyticsPageType: page?.analyticsPageType || '',
        sendCountry,
        receiveCountry,
        locale: region,
        regionCode,
        slug,
        organizationSchema,
      },
    };
  } catch (error) {
    logger.error({ error }, 'getServerSideProps failed in [[...slug]].tsx');
    return {
      notFound: true,
    };
  }
};

export default Page;
